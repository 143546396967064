import { createAuthHandler } from '@synqly/middleware/auth/api'

export { handler as default, authConfig }

const authConfig = {
  canSignIn: true,
  canSignUp: false,
  canReset: true,
  slugParam: 'backoffice-auth',
}

const handler = createAuthHandler(authConfig)
